import {
  showDefaultDialog,
  isValueStraightTree,
} from '@/helpers/helpers.js';
import i18n from '@/plugins/vueI18n.js';
import { generalKeyCheck } from './data.middleware.js';

function showAccessDeniedDialog(store, next) {
  showDefaultDialog({
    title: i18n.t('Attention'),
    text: i18n.t('Access denied, contact administrator'),
    functionOnClose: () => {
      store.dispatch('logout');
      next('/login');
    },
  });
}

export default function auth({ next, store }) {
  if (!store.getters.isLoggedIn) {
    return next();
  }
  const hasRedirect = generalKeyCheck.some((item) => {
    if (isValueStraightTree(item.treeKey)) {
      next(item.redirect);
      return true;
    }
    return false;
  });
  if (hasRedirect) {
    return next();
  }

  return showAccessDeniedDialog(store, next);
}
