<template>
  <div
    class="role-resolution scrolling-tables-static scrolling-tables-static_mini"
  >
    <v-row class="mb-1">
      <v-col
        cols="auto"
        align-self="center"
      >
        <button
          class="d-flex align-center text-color-primary"
          @click="clickReturnAllRoles()"
        >
          <v-icon
            class="mr-1 text-color-primary"
            size="20"
          >
            $back
          </v-icon>
          {{ $t("Return to all roles") }}
        </button>
      </v-col>
    </v-row>
    <div class="role-resolution__header">
      <div class="role-resolution__header-text">
        {{ roleForm.NAME }}
      </div>
      <div class="role-resolution__header-button">
        <v-btn
          v-if="isValueStraightTreeMixin('roles_edit_permissions')"
          depressed
          class="ml-4"
          color="primary"
          @click="saveRoleList()"
        >
          {{ $t("Save changes") }}
        </v-btn>
        <v-btn
          outlined
          depressed
          class="ml-4"
          color="primary"
          @click="toggleExpand(true)"
        >
          <v-icon
            class="mr-2"
            size="20"
          >
            $plus
          </v-icon>
          {{ $t("Reveal everything") }}
        </v-btn>
        <v-btn
          outlined
          depressed
          class="ml-4"
          color="primary"
          @click="toggleExpand(false)"
        >
          <v-icon
            class="mr-2"
            size="20"
          >
            $minus
          </v-icon>
          {{ $t("Hide everything") }}
        </v-btn>
      </div>
    </div>
    <role-resolution-list
      :role-structure="roleStructure"
      :editing="isValueStraightTreeMixin('roles_edit_permissions')"
      @openObjectsToVisitDialog="openObjectsToVisitDialog"
      @requestRemoveConfirm="removeConfirm"
    />

    <checkbox-list-dialog-new
      :objects="listObjects"
      :title="$t('Objects to visit')"
      :headers="[{ text: 'element_id', value: 'title' }]"
      :is-btn="true"
      item-key="ZONE_ID"
      :show-dialog="showObjectsToVisitDialog"
      @requestFormSubmit="requestFormSubmit"
      @closeDialog="showObjectsToVisitDialog = false"
    >
      <template #btn>
        {{ $t("Save") }}
      </template>
    </checkbox-list-dialog-new>

    <AppDialogWrapper
      :is-dialog-show="isDialog"
      :setting="dialogWrapperSetting"
      @close-dialog="isDialog = false"
    >
      <v-card-title class="justify-center">
        <h3>{{ $t("Save changes before exiting") }}?</h3>
      </v-card-title>
      <v-card-text class="mb-6 text-center">
        {{
          $t(
            "You have unsaved changes. You can save them now or exit without saving."
          )
        }}
      </v-card-text>
      <v-card-actions class="card__actions">
        <v-btn
          color="primary"
          class="card__action-btn"
          depressed
          @click="clickSaveExit()"
        >
          {{ $t("Save and exit") }}
        </v-btn>
        <v-btn
          class="card__action-btn"
          color="error"
          outlined
          depressed
          @click="clickExitWithoutSaving()"
        >
          {{ $t("Exit without saving") }}
        </v-btn>
      </v-card-actions>
    </AppDialogWrapper>
    <confirm-remove-dialog
      v-if="isDialogRemoveConfirm"
      @closeDialog="isDialogRemoveConfirm = false"
      @confirm="removeRequest"
    >
      <template #title>
        {{ $t("Delete object") }} «{{ roleRemove.title }}»?
      </template>
      <template #default>
        {{
          $t(
            "This action is irreversible, and it will be impossible to restore the object"
          )
        }}
      </template>
    </confirm-remove-dialog>
  </div>
</template>

<script>
import api from '@/api/index.js';
import { mapMutations, mapState } from 'vuex';
import RoleResolutionList from '@/components/tree/RoleResolutionList.vue';
import CheckboxListDialogNew from '@/components/blocks/CheckboxListDialogNew.vue';
import AppDialogWrapper from '@/components/blocks/AppDialogWrapper.vue';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types.js';
import { traverseTree } from '@/helpers/helpers.js';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types.js';
import isValueStraightTreeMixin from '@/mixins/isValueStraightTreeMixin.js';

export default {
  name: 'RoleResolution',
  components: {
    RoleResolutionList,
    CheckboxListDialogNew,
    AppDialogWrapper,
    ConfirmRemoveDialog,
  },
  mixins: [isValueStraightTreeMixin],
  props: {
    roleStructure: {
      type: Array,
      required: true,
    },
    roleStructureNotEditable: {
      type: Array,
      required: true,
    },
    roleForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showList: false,
      expandAllCounter: 0,
      showObjectsToVisitDialog: false,
      isDialog: false,
      dialogWrapperSetting: {
        dialogMaxWidth: '598px',
      },
      listObjects: [],
      roleElement: {},

      isDialogRemoveConfirm: false,
      roleRemove: {},
    };
  },
  computed: {
    ...mapState({
      roleListCommon: (state) => state.role.roleListCommon,
    }),
  },
  methods: {
    ...mapMutations([
      'updateShowList',
      'updateExpandAllCounter',
      'updateRoleListCommon',
    ]),

    removeRequest() {
      const cloneRoleListCommon = traverseTree(
        this.roleListCommon,
        (currentNode, stack) => {
          if (currentNode.key === this.roleRemove.parent_key) {
            currentNode.element_list.push({
              element_id: this.roleRemove.element_id,
              title: this.roleRemove.title,
            });
            currentNode.child = currentNode.child.filter(
              (cur) => cur.element_id !== this.roleRemove.element_id,
            );
          }
        },
      );
      this.updateRoleListCommon(cloneRoleListCommon);
      this.isDialogRemoveConfirm = false;
    },

    removeConfirm(show, roleRemove) {
      this.roleRemove = roleRemove;
      this.isDialogRemoveConfirm = show;
    },

    clickExitWithoutSaving() {
      this.$emit('hide-role-resolution');
      this.isDialog = false;
    },

    clickSaveExit() {
      this.saveRoleList();
      this.$emit('hide-role-resolution');
      this.isDialog = false;
    },

    clickReturnAllRoles() {
      if (_.isEqual(this.roleStructureNotEditable, this.roleStructure)) {
        this.clickExitWithoutSaving();
        return;
      }
      this.isDialog = true;
    },

    requestFormSubmit(element) {
      this.listObjects = _.cloneDeep(element);
      this.updateRoleListCommon(
        traverseTree(this.roleStructure, (currentNode, stack) => {
          if (this.roleElement.key !== currentNode.key) {
            return;
          }
          const newElement = element.filter((item) => {
            if (item.IS_ACCESS) {
              const struct = _.cloneDeep(
                currentNode.child.find(
                  (currentNodeItem) => currentNodeItem.element_id === -1,
                ),
              );
              struct.title = item.title;
              struct.element_id = item.element_id;
              currentNode.child = [...currentNode.child, _.cloneDeep(struct)];
            }
            return !item.IS_ACCESS;
          });
          currentNode.element_list = newElement;
        }),
      );
    },
    openObjectsToVisitDialog(roleElement) {
      this.roleElement = roleElement;
      this.listObjects = [...roleElement.element_list];
      this.showObjectsToVisitDialog = true;
    },
    triggerMutation(newValue) {
      this.myMutation(newValue);
    },
    toggleExpand(value) {
      this.updateShowList(value);
      this.updateExpandAllCounter(++this.expandAllCounter);
    },
    saveRoleList() {
      this.$store.commit(COMMON_SET_LOADING);
      api.roles
        .putRoleStructure(this.roleForm.ID, this.roleListCommon)
        .then((res) => {
          this.$emit('saveRoleStructureNotEditable');
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        })
        .finally(() => {
          this.$store.commit(COMMON_SET_LOADING, false);
        });
    },
  },
};
</script>

<style lang="scss">
.role-resolution {
  .text-color-primary {
    color: $primary;
  }

  &__header-text {
    font-size: 26px;
    font-weight: 500;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
</style>
