<template>
  <v-dialog
    :value="showDialog"
    :max-width="maxWidth"
    @click:outside="closeDialog"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon
        size="16"
        class="card__close-btn"
        @click="closeDialog"
      >
        $close
      </v-icon>
      <v-card-title
        class="card__title-wrapper card__title-wrapper_checkbox-dialog"
      >
        <h2 class="font-weight-medium">
          {{ title }}
        </h2>
        <v-chip
          v-if="subtitle"
          color="green"
          text-color="white"
          label
        >
          {{ subtitle }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="searchObjects"
          append-icon="mdi-magnify"
          :label="$t('Name')"
          outlined
          dense
          hide-details
        >
          <template #prepend-inner>
            <v-icon
              class="pt-1 mr-1 grey--text"
              size="20"
            >
              $search
            </v-icon>
          </template>
        </v-text-field>
        <div class="scroll-container">
          <v-data-table
            class="table-no-border table-no-head"
            :value="localObjects"
            :loading="tableLoading"
            :headers="headersWithoutPaddings"
            :items="[...selectedObjects, ...unselectedObjects]"
            :search="searchObjects"
            checkbox-color="primary"
            :item-key="itemKey"
            :no-data-text="`${title} ${$t('not found')}`"
            :no-results-text="`${title} ${$t('not found')}`"
            :show-select="showCheckboxes"
            disable-pagination
            hide-default-footer
            max-height="550"
            sort-by="isSelectable"
            :dense="isListDense"
          >
            <template #[`item.data-table-select`]="{ item }">
              <v-simple-checkbox
                v-if="item.id !== -1"
                v-model="item.IS_ACCESS"
              />
              <v-simple-checkbox
                v-else
                :value="allSelected"
                @input="toggleSelectAll"
              />
            </template>
            <template #[`header.data-table-select`] />
            <template #[`header.${headers[0].value}`] />
          </v-data-table>
        </div>
        <v-btn
          v-if="isBtn"
          depressed
          block
          color="primary"
          @click="requestFormSubmit"
        >
          <slot name="btn"/>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CheckboxListDialog',
  components: {},
  props: {
    objects: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
    disabledCheckbox: {
      type: Boolean,
      default: false,
    },
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: 580,
    },
    isListDense: {
      type: Boolean,
      default: false,
    },
    isBtn: {
      type: Boolean,
      default: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchObjects: '',
      tableLoading: false,
      localObjects: [],
    };
  },
  computed: {
    selectedObjects() {
      return this.localObjects.filter((item) => !!item.IS_ACCESS);
    },
    unselectedObjects() {
      return this.localObjects.filter((item) => !item.IS_ACCESS);
    },
    headersWithoutPaddings() {
      return [{ ...this.headers[0], class: 'px-1', cellClass: 'px-1' }];
    },
    allSelected() {
      return (
        this.localObjects.length > 0
        && this.localObjects.every((item) => item.IS_ACCESS)
      );
    },
  },
  watch: {
    showDialog() {
      this.localObjects = _.cloneDeep(this.objects);
    },
  },
  methods: {
    closeDialog() {
      setTimeout(() => this.$emit('closeDialog', false), 200);
    },
    keydownHandler(e) {
      if (e.key === 'Escape') {
        this.closeDialog();
      }
    },
    toggleSelectAll(value) {
      this.selectAllCheckboxes(value);
    },
    selectAllCheckboxes(value) {
      const updatedObjects = this.localObjects.map((item) => ({
        ...item,
        IS_ACCESS: value,
      }));
      this.localObjects = updatedObjects;
    },
    requestFormSubmit() {
      this.$emit('requestFormSubmit', this.localObjects);
      this.closeDialog();
    },
  },
};
</script>

<style>
.scroll-container {
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
