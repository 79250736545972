import {
  showDefaultDialog,
  isValueStraightTree,
} from '@/helpers/helpers.js';
import i18n from '@/plugins/vueI18n.js';
import { generalKeyCheck } from './data.middleware.js';

function showAccessDeniedDialog(store, next) {
  showDefaultDialog({
    title: i18n.t('Attention'),
    text: i18n.t('Access denied, contact administrator'),
    functionOnClose: () => {
      store.dispatch('logout');
      next('/login');
    },
  });
}

export default function common({ next, store, to }) {
  if (!store.getters.isLoggedIn) {
    return next();
  }
  if (!isValueStraightTree('arm_ops')) {
    return showAccessDeniedDialog(store, next);
  }

  const exactMatch = generalKeyCheck.find(
    (item) => isValueStraightTree(item.treeKey) && to.path === item.redirect,
  );

  if (exactMatch) {
    return next();
  }

  const redirectMatch = generalKeyCheck.find((item) => isValueStraightTree(item.treeKey));

  if (redirectMatch) {
    return next(redirectMatch.redirect);
  }

  return showAccessDeniedDialog(store, next);
}
