<template>
  <v-dialog
    :max-width="maxWidth"
    :value="isShow"
    @click:outside="closeDialog()"
    @keydown="keydownHandler"
  >
    <v-card v-show="dialogType === roleDealogType.home">
      <v-icon
        size="16"
        class="card__close-btn"
        @click="closeDialog()"
      >
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <div class="card__title-with-btn">
          <h3 class="card__title">
            {{ $t("Linked Roles") }}
          </h3>
          <div>
            <v-btn
              v-if="isValueStraightTreeMixin('user_edit_roles')"
              class="mr-2"
              depressed
              color="primary"
              @click="linkRole"
            >
              <v-icon
                size="16"
                left
                class="mr-3"
              >
                $plus
              </v-icon>
              {{ $t("Link a role") }}
            </v-btn>
            <v-btn
              outlined
              depressed
              color="primary"
              @click="totalRights"
            >
              <v-icon
                size="16"
                left
              >
                $show
              </v-icon>
              {{ $t("Total rights") }}
            </v-btn>
          </div>
        </div>
      </v-card-title>

      <v-text-field
        v-model="searchPlateNum"
        class="mb-4"
        dense
        outlined
        hide-details="auto"
        clearable
        clear-icon="$closeCircle"
        :label="$t('Name of the role')"
        @input="filterLinkedRoles"
      >
        <template #prepend-inner>
          <v-icon
            class="pt-1 mr-1 grey--text"
            size="20"
          >
            $search
          </v-icon>
        </template>
      </v-text-field>

      <div
        v-show="!externalReaders.length"
        class="no-role__box"
      >
        <v-icon
          class="no-role__icon mb-4"
          size="92"
          color="primary"
        >
          $noUserRole
        </v-icon>
        <span class="no-role__title">
          {{ $t("The user does not have any associated roles") }}
        </span>
        <span class="no-role__subtitle">
          {{ $t("To link a role, click “Link a role”") }}
        </span>
      </div>

      <v-data-table
        v-show="externalReaders.length"
        class="table-striped"
        :headers="translatedTableHeaders"
        :items="copeExternalReaders"
        :no-data-text="$t('Data not found')"
        :no-results-text="$t('Data not found')"
        :footer-props="{
          'items-per-page-text': $t('Lines per page'),
          'items-per-page-options': [5, 10],
        }"
      >
        <template #[`item.STATUS_NAME`]="{ item }">
          <div :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
            {{ item.STATUS_NAME }}
          </div>
        </template>
        <template #[`item.ACTIONS`]="{ item }">
          <div class="d-inline-flex">
            <v-icon
              class="mr-4"
              color="primary"
              @click="roleRights(item)"
            >
              $show
            </v-icon>
            <v-icon
              v-if="isValueStraightTreeMixin('user_edit_roles')"
              color="red"
              @click="removeExternalReader(item)"
            >
              $delete
            </v-icon>
          </div>
        </template>
        <template
          #footer.page-text="{ pageStart, pageStop, itemsLength }"
        >
          {{ pageStart }} - {{ pageStop }} {{ $t("of") }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>

    <add-roles-dialog
      v-show="dialogType === roleDealogType.addRole"
      :table-items="roleUserNotLinked"
      @close-dialog="closeDialog()"
      @go-back="goBack()"
      @addRoleUser="addRoleUser"
    />

    <viewing-user-role
      v-show="showViewingUserRole"
      :role-structure="roleStructure"
      :user="user"
      :role-dealog-type-props="dialogType"
      :role="clickOnRole"
      @close-dialog="closeDialog()"
      @go-back="goBack()"
      @addRoleUser="addRoleUser"
    />
  </v-dialog>
</template>

<script>
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import isValueStraightTreeMixin from '@/mixins/isValueStraightTreeMixin.js';
import addRolesDialog from './AddRolesDialog.vue';
import ViewingUserRole from './ViewingUserRole.vue';
import { roleDealogType } from './data.user';

export default {
  components: { addRolesDialog, ViewingUserRole },
  mixins: [isValueStraightTreeMixin],
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableItems: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxWidth: '730',
      externalReaders: [],
      copeExternalReaders: [],
      searchPlateNum: '',
      roleUserNotLinked: [],
      roleDealogType,
      dialogType: roleDealogType.home,
      roleStructure: [],
      clickOnRole: {},
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({
        ...elem,
        text: this.$t(elem.text),
      }));
    },
    showViewingUserRole() {
      return (
        this.dialogType === this.roleDealogType.viewingRoleRights
        || this.dialogType === this.roleDealogType.viewingTotalRoleRights
      );
    },
  },
  watch: {
    tableItems() {
      this.externalReaders = this.tableItems;
      this.filterLinkedRoles();
    },
  },
  methods: {
    goBack() {
      this.maxWidth = '730';
      this.dialogType = roleDealogType.home;
    },
    filterLinkedRoles() {
      if (!this.searchPlateNum) {
        this.copeExternalReaders = _.cloneDeep(this.externalReaders);
        return;
      }
      const query = this.searchPlateNum.toLowerCase();
      this.copeExternalReaders = this.externalReaders.filter(
        (role) => role.NAME.toLowerCase().includes(query)
          || role.DESCRIPTION?.toLowerCase().includes(query),
      );
    },

    async removeExternalReader(externalReader) {
      this.clickOnRole = externalReader;
      await api.users
        .unbindUserRole({
          id: this.user.ID,
          role_id: externalReader.ID,
        })
        .then((res) => {
          const currentReaderIndex = this.externalReaders.findIndex(
            (reader) => reader.ID === externalReader.ID,
          );
          this.externalReaders.splice(currentReaderIndex, 1);
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
    },
    linkRole() {
      this.maxWidth = '730';
      this.dialogType = roleDealogType.addRole;

      api.users
        .getUserRole({ id: this.user.ID, is_bind: false })
        .then((res) => {
          this.roleUserNotLinked = res.data.DATA;
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
    },
    addRoleUser(role_id) {
      api.users
        .bindUserRole({ id: this.user.ID, role_id })
        .then((res) => {
          this.externalReaders = [...this.externalReaders, ...res.data.DATA];
          this.filterLinkedRoles();
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
    },
    roleRights(item) {
      this.clickOnRole = item;
      api.roles
        .getRolePermission(item.ID)
        .then((res) => {
          this.roleStructure = JSON.parse(res.data.DATA);
          this.maxWidth = '934';
          this.dialogType = roleDealogType.viewingRoleRights;
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
    },
    totalRights() {
      api.roles
        .getUserPermission(this.user.ID)
        .then((res) => {
          this.roleStructure = JSON.parse(res.data.DATA);
          this.maxWidth = '934';
          this.dialogType = roleDealogType.viewingTotalRoleRights;
        })
        .catch((error) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, error.response.data.MESSAGE);
        });
    },
    closeDialog() {
      this.maxWidth = '730';
      this.$emit('close-dialog');
      this.dialogType = roleDealogType.home;
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) {
        this.closeDialog();
      }
    },
  },
};
</script>

<style lang="scss">
.no-role {
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 197px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}
</style>
