<template>
  <v-dialog
    max-width="890"
    :value="isShow"
    @click:outside="$emit('close-dialog')"
    @keydown="keydownHandler"
  >
    <v-card>
      <v-icon
        size="16"
        class="card__close-btn"
        @click="$emit('close-dialog')"
      >
        $close
      </v-icon>
      <v-card-title class="card__title-wrapper">
        <h3 class="card__title">
          {{ isNewRequestForm ? $t("Parking pass") : $t("Editing a pass") }}
        </h3>
      </v-card-title>

      <v-card-text class="text-center pb-16 px-12">
        <v-form
          ref="carForm"
          v-model="formValid"
          fast-fail
          @keyup.native.enter="formSubmit"
        >
          <v-card
            class="d-flex justify-space-between px-0 py-0"
            flat
          >
            <v-card
              class="d-flex flex-column text-left px-0 py-0"
              style="width: 370px"
              flat
            >
              <v-autocomplete
                :value="requestForm.OBJECT_ID"
                :rules="carFormRules.OBJECT_ID"
                disabled
                outlined
                :items="filteredLocalObjects"
                :menu-props="{ offsetY: true }"
                append-icon="$down"
                hide-details="auto"
                class="mb-5"
              >
                <template #label>
                  {{ $t("Name of visited objects")
                  }}<span class="red--text">*</span>
                </template>
              </v-autocomplete>
              <v-select
                v-model="requestForm.STATE"
                :items="requestStatuses()"
                :rules="carFormRules.STATE"
                :disabled="disabledStatusInput"
                :menu-props="{ offsetY: true }"
                class="mb-5"
                append-icon="$down"
                hide-details="auto"
                outlined
              >
                <template #label>
                  {{ $t("Status") }}<span class="red--text">*</span>
                </template>
              </v-select>
              <div class="box-date-time-picker">
                <date-time-picker
                  v-model="carForm.DATE_BEGIN"
                  :disabled="isUpdateRequestFormDisabledInput"
                  class="mb-5"
                  :class="{
                    'date-time-picker__error': isEmptyDateBegin,
                  }"
                  :placeholder="$t('Start actions') + '*'"
                  :color="receiveColorStates(isEmptyDateBegin)"
                  @getDate="setNewDateBegin"
                />
                <date-time-picker
                  v-model="carForm.DATE_END"
                  :disabled="isUpdateRequestFormDisabledInput"
                  :class="{
                    'date-time-picker__error': isEmptyDateEnd,
                  }"
                  :color="receiveColorStates(isEmptyDateEnd)"
                  class="mb-5"
                  :placeholder="$t('End actions') + '*'"
                  @getDate="setNewDateEnd"
                />
              </div>
              <v-text-field
                v-model="carForm.CAR_NUM"
                :rules="carFormRules.CAR_NUM"
                :disabled="isUpdateRequestFormDisabledInput"
                hide-details="auto"
                class="mb-5"
                outlined
                :hint="$t('Use Cyrillic characters to enter the State number')"
                persistent-hint
              >
                <template #label>
                  {{ $t("License plate") }}<span class="red--text">*</span>
                </template>
              </v-text-field>
              <v-autocomplete
                v-model="carForm.CURRENT_BRAND"
                :items="carsBrands"
                :disabled="isUpdateRequestFormDisabledInput"
                :menu-props="{ offsetY: true }"
                class="mb-5"
                append-icon="$down"
                hide-details="auto"
                :no-data-text="$t('Data not found')"
                outlined
                @input="
                  (value) => updateCurrent(value, 'carsBrands', 'CURRENT_BRAND')
                "
              >
                <template #label>
                  {{ $t("Car brand") }}
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="carForm.CURRENT_MODEL"
                :items="carsModels"
                :menu-props="{ offsetY: true }"
                :disabled="
                  !carForm.CURRENT_BRAND || isUpdateRequestFormDisabledInput
                "
                class="mb-5"
                append-icon="$down"
                hide-details="auto"
                :no-data-text="$t('Data not found')"
                outlined
                @input="
                  (value) => updateCurrent(value, 'carsModels', 'CURRENT_MODEL')
                "
              >
                <template #label>
                  {{ $t("Car model") }}
                </template>
              </v-autocomplete>
            </v-card>
            <v-card
              class="d-flex flex-column text-left px-0 py-0"
              style="width: 370px"
              flat
            >
              <v-text-field
                v-model="requestForm.DATA.V_NAME"
                :disabled="isUpdateRequestFormDisabledInput"
                hide-details="auto"
                class="mb-5"
                outlined
              >
                <template #label>
                  {{ $t("Name of visitor") }}
                </template>
              </v-text-field>

              <v-text-field
                v-model="requestForm.DATA.V_PHONE"
                :disabled="!canEditFields() || isUpdateRequestFormDisabledInput"
                hide-details="auto"
                class="mb-5"
                outlined
              >
                <template #label>
                  {{ $t("Phone number") }}
                </template>
              </v-text-field>
              <v-text-field
                v-model="requestForm.DATA.V_EMAIL"
                :disabled="!canEditFields() || isUpdateRequestFormDisabledInput"
                hide-details="auto"
                class="mb-5"
                outlined
                type="email"
              >
                <template #label>
                  {{ $t("Email") }}
                  <span
                    v-if="isRequireField('V_EMAIL')"
                    class="red--text"
                  >*</span>
                </template>
              </v-text-field>
              <v-textarea
                v-model="requestForm.DATA.NOTICE"
                :disabled="isUpdateRequestFormDisabledInput"
                :label="$t('Comments')"
                class="custom-textarea mb-5"
                rows="4"
                row-height="30"
                hide-details="auto"
                outlined
                no-resize
              />
              <span :class="{ 'text--disabled': !canEditFields() }">
                {{ $t("Reusable pass") }} <span class="red--text">*</span>
              </span>
              <multi-pass-radio-btn-new
                :is-multi-pass-prop="requestForm.MULTI_PASS"
                :allowed-pass-type-prop="allowedPassType"
                :default-pass-type-prop="defaultPassType"
                :is-new-request-form-prop="isNewRequestForm"
                :disabled="isUpdateRequestFormDisabledInput"
                :rules-props="carFormRules.MULTI_PASS"
                @update-state-fabric="updateMultiPass"
              />
              <div class="mb-1 text-left">
                {{ $t("Inspection required") }}
              </div>
              <v-radio-group
                v-model="carForm.INSPECTION"
                :disabled="isUpdateRequestFormDisabledInput"
                hide-details="auto"
                class="mb-5 mt-0"
                row
              >
                <v-radio
                  :value="true"
                  class="mr-4"
                >
                  <template #label>
                    <span class="text--primary">{{ $t("Yes") }}</span>
                  </template>
                </v-radio>

                <v-radio :value="false">
                  <template #label>
                    <span class="text--primary">{{ $t("No") }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card>
          </v-card>
          <v-btn
            depressed
            block
            color="primary"
            :class="{
              btn_disabled: !(
                formValid &&
                !!carForm.DATE_BEGIN &&
                !!carForm.DATE_END
              ),
            }"
            @click="formSubmit"
          >
            {{ $t("Save") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api/index.js';
import moment from 'moment';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types.js';
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import { ALLOWED_PASS_TYPES } from '@/constants/index.js';
import { mapGetters, mapState } from 'vuex';
import updateStateMixin from '@/mixins/updateStateMixin.js';
import MultiPassRadioBtn from '@/components/blocks/MultiPassRadioBtn.vue';
import MultiPassRadioBtnNew from '@/components/blocks/MultiPassRadioBtnNew.vue';
import dateHelpers from '@/helpers/dateHelpers.js';

export default {
  name: 'ParkingDialog',
  components: {
    DateTimePicker,
    MultiPassRadioBtn,
    MultiPassRadioBtnNew,
  },
  mixins: [updateStateMixin],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    objectId: {
      type: [Number, null],
      default: null,
    },
    carFormProps: {
      type: Object,
      required: true,
    },
    isNewRequestForm: {
      type: Boolean,
      required: true,
    },
    requestForm: {
      type: Object,
      default: {},
    },
    requestFormConfig: {
      type: [Object, Array],
      required: true,
    },
    filteredLocalObjects: {
      type: Array,
    },
    allowedPassType: {
      type: Number,
    },
    defaultPassType: {
      type: Number,
    },
  },
  data() {
    return {
      formValid: false,
      enumRequestState: [],
      formMultiPass: false,

      carForm: {
        ID: null,
        STATE: null,
        CAR_NUM: null,
        CURRENT_BRAND: {},
        CURRENT_MODEL: {},
        DATE_BEGIN: null,
        DATE_END: null,
        INSPECTION: false,
        COMMENTS: '',
      },

      carFormRules: {
        V_NAME: [(v) => !!v || this.$t('You must enter «Name of visitor»')],
        V_PHONE: [
          (v) => (!!v && !!/^[\d\+][\d\(\)\ -]{4,14}\d$/.test(v))
            || this.$t('You must enter «Phone number»'),
        ],
        V_EMAIL: [
          (v) => (!!v && !!/^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i.test(v))
            || this.$t('You must enter «Email»'),
        ],
        STATE: [(v) => !!v || v === 0 || this.$t('You must enter «Status»')],
        OBJECT_ID: [
          (v) => !!v || this.$t('You must select «Object of visit».'),
        ],
        CAR_BRAND: [(v) => !!v || `${this.$t('You must select «Car brand»')}.`],
        CAR_MODEL: [(v) => !!v || `${this.$t('You must select «Car model»')}.`],
        CAR_NUM: [
          (v) => !!v || `${this.$t('You need to fill in «License plate»')}.`,
        ],
        MULTI_PASS: [
          (v) => {
            if (
              this.allowedPassType === ALLOWED_PASS_TYPES.singleAndMultiPass
            ) {
              return true;
            }
            const isPassTypeSinglePass = this.allowedPassType === ALLOWED_PASS_TYPES.multiPass;
            if (this.requestForm.MULTI_PASS === isPassTypeSinglePass) {
              return true;
            }
            return `${this.$t('It is necessary to «Change the value»')}.`;
          },
        ],
      },
      isEmptyDateBegin: false,
      isEmptyDateEnd: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.model,
    }),
    ...mapGetters(['carsGroupedByBrand', 'carsBrands']),
    carsModels() {
      if (
        !this.$store.getters.carsGroupedByBrand[
          this.carForm.CURRENT_BRAND.value
        ]?.length
      ) {
        return [];
      }
      return this.$store.getters.carsGroupedByBrand[
        this.carForm.CURRENT_BRAND.value
      ].map((car) => ({ text: car.NAME, value: car.ID }));
    },
    isUpdateRequestFormDisabledInput() {
      return this.isNewRequestForm
        ? false
        : !this.requestForm.IS_MOD?.parameters;
    },
    disabledStatusInput() {
      return this.isNewRequestForm || this.requestStatuses.length !== 0;
    },
  },
  watch: {
    isShow(newVal) {
      for (const key of Object.keys(this.carForm)) {
        if (key === 'INSPECTION' && this.isNewRequestForm) {
          this.carForm[key] = false;
          break;
        }
        this.carForm[key] = '';
      }
      if (this.isShow && this.$refs.carForm && this.isNewRequestForm) {
        this.$refs.carForm.resetValidation();
      }
      if (this.isShow && this.isNewRequestForm) {
        this.carForm.DATE_BEGIN = dateHelpers.getTomorrowTime({ hour: 7 });
        this.carForm.DATE_END = dateHelpers.getTomorrowTime({ hour: 19 });
        const isActiveInput = this.defaultPassType === ALLOWED_PASS_TYPES.multiPass;
        this.requestForm.MULTI_PASS = isActiveInput;
        this.formMultiPass = isActiveInput;
      }
      if (newVal && !this.isNewRequestForm) {
        this.validateMultiPass();
        this.formMultiPass = this.requestForm.MULTI_PASS;
      }
    },
    carFormProps() {
      this.carForm = this.carFormProps;
    },
    'carForm.DATE_BEGIN': function (newTime) {
      if (!newTime) {
        this.isEmptyDateBegin = true;
        return;
      }
      const dateBegin = new Date(newTime);
      const dateEnd = new Date(this.carForm.DATE_END);
      if (dateBegin.getTime() > dateEnd.getTime()) {
        this.carForm.DATE_END = dateHelpers.getTomorrowTime({
          date: dateBegin,
          hour: 19,
        });
      }
      this.isEmptyDateBegin = false;
    },
    'carForm.DATE_END': function (newTime) {
      if (!newTime) {
        this.isEmptyDateEnd = true;
        return;
      }
      const dateEnd = new Date(newTime);
      const dateBegin = new Date(this.carForm.DATE_BEGIN);
      if (dateBegin.getTime() > dateEnd.getTime()) {
        this.carForm.DATE_BEGIN = dateHelpers.getYesterdayTime({
          date: dateEnd,
          hour: 7,
        });
      }
      this.isEmptyDateEnd = false;
    },
  },
  mounted() {
    const enumState = [];
    if (this.$store.getters.getEnums['ENUM.Request.STATE']?.length) {
      this.$store.getters.getEnums['ENUM.Request.STATE'].forEach((item) => {
        enumState.push({
          text: item.NAME,
          value: item.NUMBER,
        });
      });
      this.enumRequestState = enumState;
    }
  },

  methods: {
    validateMultiPass() {
      this.$nextTick(() => {
        if (this.$refs.carForm) {
          this.$refs.carForm.validate();
        }
      });
    },
    addCurrent(item) {
      return item;
    },
    createRequestFormModel() {
      return {
        id: this.carForm.ID,
        code: this.carForm.CAR_NUM,
        object_id: this.objectId,
        type_id: 2,
        state: this.requestForm.STATE,
        date_begin: this.carForm.DATE_BEGIN
          ? moment(this.carForm.DATE_BEGIN).format('DD.MM.YYYY HH:mm:00')
          : null,
        date_end: this.carForm.DATE_END
          ? moment(this.carForm.DATE_END).format('DD.MM.YYYY HH:mm:00')
          : null,
        multi_pass: this.formMultiPass,
        inspection: this.carForm.INSPECTION,
        data: JSON.stringify({
          V_NAME: this.requestForm.DATA.V_NAME,
          V_PHONE: this.requestForm.DATA.V_PHONE,
          V_EMAIL: this.requestForm.DATA.V_EMAIL,
          CAR_BRAND: this.carForm.CURRENT_BRAND.value,
          CAR_MODEL: this.carForm.CURRENT_MODEL.value,
          CAR_BRAND_NAME: this.carForm.CURRENT_BRAND.text,
          CAR_MODEL_NAME: this.carForm.CURRENT_MODEL.text,
          NOTICE: this.requestForm.DATA.NOTICE ?? '',
        }),
      };
    },
    updateCurrent(value, targetArray, targetProperty) {
      if (targetProperty === 'CURRENT_BRAND') {
        this.carForm.CURRENT_MODEL = {};
      }
      const selectedCar = this[targetArray].find((car) => car.value === value);
      this.carForm[targetProperty] = selectedCar;
    },
    async addNewParkingRequest() {
      const response = await api.requests.create(this.createRequestFormModel());
      this.$emit('add-request', response.data.DATA);
      this.$emit('close-dialog');
    },
    async editParkingRequest() {
      const response = await api.requests.update(
        this.carForm.ID,
        this.createRequestFormModel(),
      );
      this.$emit('edit-request', response.data.DATA);
      this.$emit('close-dialog');
    },
    async formSubmit() {
      try {
        if (this.isNewRequestForm) {
          this.addNewParkingRequest();
          return;
        }
        this.editParkingRequest();
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE),
        );
      }
    },
    keydownHandler(e) {
      if (e.key === 'Escape') {
        this.$emit('close-dialog');
      }
    },
    setNewDateBegin(time) {
      this.carForm.DATE_BEGIN = time;
    },
    setNewDateEnd(time) {
      this.carForm.DATE_END = time;
    },
    canEditFields() {
      if (this.isUpdateRequestFormDisabledInput) {
        return false;
      }
      if (
        this.isNewRequestForm
        || this.$store.getters?.user?.PERMISSION?.web_admin
      ) {
        return true;
      }
      if (
        this.requestForm.CREATE_USER_ID === this.currentUser.ID
        && this.requestForm.STATE === 0
      ) {
        return true;
      }
      return this.requestForm.IS_MOD?.parameters;
    },
    isRequireField(sidValue) {
      return !!this.requestFormConfig?.find((item) => item.SID === sidValue)
        ?.IS_NOT_NULL;
    },
    requestStatuses() {
      return this.enumRequestState.filter(
        (status) => this.requestForm.IS_MOD?.edit_status.includes(status.value)
          || this.requestForm.STATE === status.value,
      );
    },
    updateMultiPass(value) {
      this.formMultiPass = value;
    },
    receiveColorStates(value) {
      return value ? '$accent-pink' : 'primary';
    },
  },
};
</script>

<style lang="scss">
</style>
