<template>
  <v-dialog
    :value="isShow"
    max-width="890"
    @keydown="keydownHandler"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-icon
        size="16"
        class="card__close-btn"
        @click="closeDialog()"
      >
        $close
      </v-icon>
      <h3 class="text-h2 mb-5">
        {{ isNewRequestForm ? $t('Adding a pass') : $t('Editing a pass') }}
      </h3>
      <v-card-text class="text-center pb-16 px-12">
        <v-form
          ref="passForm"
          v-model="requestFormValid"
          class="requests__form"
          @keyup.native.enter="requestFormSubmit"
        >
          <v-card
            class="d-flex justify-space-between px-0 py-0"
            flat
          >
            <v-card
              class="requests__dialog-card"
              flat
            >
              <v-autocomplete
                :value="passForm.OBJECT_ID"
                :rules="requestFormRules.OBJECT_ID"
                disabled
                outlined
                :items="objects"
                :menu-props="{ offsetY: true }"
                append-icon="$down"
                hide-details="auto"
                class="mb-5"
              >
                <template #label>
                  {{ $t('Name of visited objects')
                  }}<span class="red--text">*</span>
                </template>
              </v-autocomplete>
              <v-select
                v-model="passForm.STATE"
                :items="requestStatuses"
                :rules="requestFormRules.STATE"
                :disabled="isNewRequestForm || !(requestStatuses.length > 1)"
                :menu-props="{ offsetY: true }"
                class="mb-5"
                append-icon="$down"
                hide-details="auto"
                outlined
              >
                <template #label>
                  {{ $t('Status') }}<span class="red--text">*</span>
                </template>
              </v-select>
              <div class="d-flex">
                <date-time-picker
                  v-model="passForm.DATE_BEGIN"
                  :disabled="!canEditFields()"
                  class="mb-5 mr-3"
                  :class="{
                    'date-time-picker__error': isEmptyDateBegin,
                  }"
                  :color="receiveColorStates(isEmptyDateBegin)"
                  :placeholder="$t('Start actions') + '*'"
                  @getDate="setNewDateBegin"
                  @closeCalendar="setNewDateBegin"
                />
                <date-time-picker
                  v-model="passForm.DATE_END"
                  :disabled="!canEditFields()"
                  class="mb-5"
                  :class="{
                    'date-time-picker__error': isEmptyDateEnd,
                  }"
                  :color="receiveColorStates(isEmptyDateEnd)"
                  :placeholder="$t('End actions') + '*'"
                  @getDate="setNewDateEnd"
                  @closeCalendar="setNewDateEnd"
                />
              </div>
              <date-time-picker
                v-show="!isNewRequestForm"
                :value="passForm.DATE_FIRST_PASS"
                class="mb-5"
                :placeholder="$t('Date and time of the first pass')"
                @getDate="setNewDateEnd"
              />
              <v-text-field
                v-model="passForm.DATA.V_NAME"
                :disabled="!canEditFields()"
                :rules="requestFormRules.DATA.V_NAME"
                hide-details="auto"
                class="mb-5"
                outlined
              >
                <template #label>
                  {{ $t('Name of visitor') }}
                  <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-card>
            <v-card
              class="requests__dialog-card"
              flat
            >
              <v-select
                v-show="!isNewRequestForm"
                :value="passForm.INSIDE"
                :items="translatedInsideFlags()"
                disabled
                :menu-props="{ offsetY: true }"
                append-icon="$down"
                hide-details="auto"
                outlined
              >
                <template #label>
                  {{ $t('Pass inside status') }}
                </template>
              </v-select>
              <v-text-field
                v-model="passForm.DATA.V_PHONE"
                :rules="requestFormRules.DATA.V_PHONE"
                :disabled="!canEditFields()"
                hide-details="auto"
                outlined
              >
                <template #label>
                  {{ $t('Phone number') }}
                  <span
                    v-if="isRequireField('V_PHONE')"
                    class="red--text"
                  >*</span>
                </template>
              </v-text-field>
              <v-text-field
                v-model="passForm.DATA.V_EMAIL"
                :rules="requestFormRules.DATA.V_EMAIL"
                :disabled="!canEditFields()"
                hide-details="auto"
                outlined
                type="email"
              >
                <template #label>
                  {{ $t('Email') }}
                  <span
                    v-if="isRequireField('V_EMAIL')"
                    class="red--text"
                  >*</span>
                </template>
              </v-text-field>

              <v-textarea
                v-model="passForm.DATA.NOTICE"
                :disabled="!canEditFields()"
                :label="$t('Comments')"
                :class="{ 'mt-1': !isNewRequestForm }"
                :rows="1"
                row-height="30"
                hide-details="auto"
                outlined
                no-resize
              />
              <span :class="{ 'text--disabled': !canEditFields() }">
                {{ $t('Reusable pass') }} <span class="red--text">*</span>
              </span>
              <multi-pass-radio-btn-new
                :is-multi-pass-prop="passForm.MULTI_PASS"
                :allowed-pass-type-prop="allowedPassType"
                :default-pass-type-prop="defaultPassType"
                :is-new-request-form-prop="isNewRequestForm"
                :is-disabled-all="!canEditFields()"
                :rules-props="requestFormRules.MULTI_PASS"
                @update-state-fabric="updateMultiPass"
              />
            </v-card>
          </v-card>
          <v-btn
            depressed
            block
            color="primary"
            :class="{
              btn_disabled: !(
                requestFormValid &&
                !!passForm.DATE_BEGIN &&
                !!passForm.DATE_END
              ),
            }"
            @click="requestFormSubmit"
          >
            {{ isNewRequestForm ? $t('Add') : $t('Save') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import api from '@/api/index.js';
import dateHelpers from '@/helpers/dateHelpers.js';
import {
  ALLOWED_PASS_TYPES,
  FLAG_INSIDE,
} from '@/constants/index.js';
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';
import MultiPassRadioBtnNew from '@/components/blocks/MultiPassRadioBtnNew.vue';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types.js';

export default {
  name: 'PassDialog',
  components: {
    DateTimePicker,
    MultiPassRadioBtnNew,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isNewRequestForm: {
      type: Boolean,
      required: true,
    },
    passFormProps: {
      type: Object,
      required: true,
    },
    objects: {
      type: Array,
      required: true,
    },
    requestStatuses: {
      type: Array,
      required: true,
    },
    requestFormConfig: {
      type: [Object, Array],
      required: true,
    },
    defaultPassType: {
      type: [Number, null],
      default: null,
    },
    allowedPassType: {
      type: [Number, null],
      default: null,
    },
    objectId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      FLAG_INSIDE,
      isEmptyDateBegin: false,
      isEmptyDateEnd: false,
      requestFormValid: false,
      formMultiPass: false,
      passForm: {},
      requestFormRules: {
        OBJECT_ID: [
          (v) => !!v || this.$t('You must select «Object of visit».'),
        ],
        MULTI_PASS: [
          () => {
            if (
              this.allowedPassType === ALLOWED_PASS_TYPES.singleAndMultiPass
            ) {
              return true;
            }
            const isPassTypeSinglePass = this.allowedPassType === ALLOWED_PASS_TYPES.multiPass;
            if (this.passForm.MULTI_PASS === isPassTypeSinglePass) {
              return true;
            }
            return `${this.$t('It is necessary to «Change the value»')}.`;
          },
        ],
        DATE_BEGIN: [(v) => !!v || this.$t('You must enter «Start date»')],
        DATE_END: [(v) => !!v || this.$t('You must enter «End date»')],
        STATE: [(v) => !!v || v === 0 || this.$t('You must enter «Status»')],
        DATA: {
          V_PHONE: [
            (v) => !!v
              || !this.isRequireField('V_PHONE')
              || this.$t('You must enter «Phone number»'),
          ],
          V_NAME: [
            (v) => !!v
              || this.$t(
                'It is necessary to fill in the "Full name of the Visitor"',
              ),
          ],
          V_EMAIL: [
            (v) => !!v
              || !this.isRequireField('V_EMAIL')
              || this.$t('You must enter «Email»'),
          ],
        },
      },
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.model,
    }),
    ...mapGetters(['getEnums']),
  },
  watch: {
    passFormProps: {
      immediate: true,
      handler(newProps) {
        if (newProps) {
          this.passForm = _.cloneDeep(newProps);
        }
      },
    },
    isShow(newVal) {
      if (this.isShow && this.$refs.passForm && this.isNewRequestForm) {
        this.$refs.passForm.resetValidation();
      }
      if (this.isShow && this.isNewRequestForm) {
        this.passForm.DATE_BEGIN = dateHelpers.getTomorrowTime({ hour: 7 });
        this.passForm.DATE_END = dateHelpers.getTomorrowTime({ hour: 19 });
        const isActiveInput = this.defaultPassType === ALLOWED_PASS_TYPES.multiPass;
        this.passForm.MULTI_PASS = isActiveInput;
        this.formMultiPass = isActiveInput;
      }
      if (newVal && !this.isNewRequestForm) {
        this.validateMultiPass();
        this.formMultiPass = this.passForm.MULTI_PASS;
      }
    },
    'passForm.DATE_BEGIN': function (newTime) {
      if (!newTime) {
        this.isEmptyDateBegin = true;
        return;
      }
      const dateBegin = new Date(newTime);
      const dateEnd = new Date(this.passForm.DATE_END);
      if (dateBegin.getTime() > dateEnd.getTime()) {
        this.passForm.DATE_END = dateHelpers.getTomorrowTime({
          date: dateBegin,
          hour: 19,
        });
      }
      this.isEmptyDateBegin = false;
    },
    'passForm.DATE_END': function (newTime) {
      if (!newTime) {
        this.isEmptyDateEnd = true;
        return;
      }
      const dateEnd = new Date(newTime);
      const dateBegin = new Date(this.passForm.DATE_BEGIN);
      if (dateBegin.getTime() > dateEnd.getTime()) {
        this.passForm.DATE_BEGIN = dateHelpers.getYesterdayTime({
          date: dateEnd,
          hour: 7,
        });
      }
      this.isEmptyDateEnd = false;
    },
  },
  methods: {
    canEditFields() {
      if (this.isNewRequestForm) {
        return true;
      }
      if (
        this.passForm.CREATE_USER_ID === this.currentUser.ID
        && this.passForm.STATE === 0
      ) {
        return true;
      }
      return this.passForm.IS_MOD?.parameters;
    },
    createRequestFormModel() {
      return {
        id: this.passForm.ID,
        type_id: this.passForm.TYPE_ID,
        object_id: this.objectId,
        state: this.passForm.STATE,
        multi_pass: this.formMultiPass,
        inspection: this.passForm.INSPECTION,
        date_begin: this.passForm.DATE_BEGIN
          ? moment(this.passForm.DATE_BEGIN).format('DD.MM.YYYY HH:mm:00')
          : null,
        date_end: this.passForm.DATE_END
          ? moment(this.passForm.DATE_END).format('DD.MM.YYYY HH:mm:00')
          : null,
        date_first_pass: this.passForm.DATE_FIRST_PASS
          ? moment(this.passForm.DATE_FIRST_PASS).format('DD.MM.YYYY HH:mm:00')
          : null,
        data: JSON.stringify({
          V_NAME: this.passForm.DATA.V_NAME,
          V_PHONE: this.passForm.DATA.V_PHONE,
          V_EMAIL: this.passForm.DATA.V_EMAIL,
          NOTICE: this.passForm.DATA.NOTICE ?? '',
        }),
      };
    },
    async addNewPassRequest() {
      const response = await api.requests.create(this.createRequestFormModel());
      this.$emit('add-request', response.data.DATA);
      this.$emit('close-dialog');
    },
    async editParkingRequest() {
      const response = await api.requests.update(
        this.passForm.ID,
        this.createRequestFormModel(),
      );
      this.$emit('edit-request', response.data.DATA);
      this.$emit('close-dialog');
    },
    requestFormSubmit() {
      try {
        if (this.isNewRequestForm) {
          this.addNewPassRequest();
          return;
        }
        this.editParkingRequest();
      } catch (error) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t(error.response.data.MESSAGE),
        );
      }
    },
    validateMultiPass() {
      this.$nextTick(() => {
        if (this.$refs.passForm) {
          this.$refs.passForm.validate();
        }
      });
    },
    updateMultiPass(value) {
      this.formMultiPass = value;
    },
    isRequireField(sidValue) {
      return !!this.requestFormConfig?.find((item) => item.SID === sidValue)
        ?.IS_NOT_NULL;
    },
    keydownHandler(e) {
      if (('key', e.key === 'Escape')) {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
    receiveColorStates(value) {
      return value ? '$accent-pink' : 'primary';
    },
    setNewDateBegin(time) {
      this.passForm.DATE_BEGIN = time;
    },
    setNewDateEnd(time) {
      this.passForm.DATE_END = time;
    },
    translatedInsideFlags() {
      return this.FLAG_INSIDE.map((flag) => ({
        ...flag,
        text: this.$t(flag.text),
      }));
    },
  },
};
</script>

<style lang="scss">
</style>
